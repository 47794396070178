import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import queryToFilterValidaion from '../../../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../../../utility';

export const fields = {
	affiliateReference: 'affiliate_reference', // string;
	createdBy         : 'author_id',
	autoSendLog       : 'autoSendLog',
	betPerLine        : 'bet_per_line',
	bonusType         : 'bonus_type',
	casinoGameID      : 'casino_game_id',
	casinoGameName    : 'casino_game_name',
	createDate        : 'create_date',
	currencyIDs       : 'currency_ids',
	currency          : 'currency_ids',

	daysToExpireBonus : 'days_to_expire_bonus',
	daysToExpireWallet: 'days_to_expire_wallet',
	denomination      : 'denomination',
	denominationValue : 'denomination_value',
	forced            : 'forced',
	id                : 'id',

	lines           : 'lines',
	logoUrl         : 'logo_url',
	name            : 'name', 
	spinsTotal      : 'spins_total',
	titles          : 'titles',
	bonusID         : 'bonus_id',
	title           : 'title',
	description     : 'description',
	langID          : 'lang_id',
	freeSpinType    : 'type_id',
	userFilter      : 'user_filter',
	wageringTurnover: 'wagering_turnover',
	websiteID       : 'website_id',
	count           : 'count',

	integratorIDs: 'integrator_ids',
	providerIDs  : 'provider_ids',
	categoryIDs  : 'category_ids',
	tagIDs       : 'tag_ids',
	gameIDs      : 'game_ids',
	
	//filter
	createdDateFrom: 'create_date_from',	// Date;
	createdDateTo  : 'create_date_to',		// Date;
	
};
export const userFilterFields = {
	id              : 'id',
	sex             : 'sex',
	email           : 'email',
	ageTo           : 'age_to',
	domain          : 'domain',
	country         : 'country',
	dateFrom        : 'date_from',
	dateTo          : 'date_to',
	ageFrom         : 'age_from',
	username        : 'username',
	lastName        : 'last_name',
	statusID        : 'status_id',
	firstName       : 'first_name',
	riskGroup       : 'risk_group',
	websiteID       : 'website_id',
	customerIP      : 'customer_ip',
	currencyIDs     : 'currency_ids',
	maxStakeTo      : 'max_stake_to',
	minStakeTo      : 'min_stake_to',
	betsCountTo     : 'bets_count_to',
	birthDateTo     : 'birth_date_to',
	gamingStatus    : 'gaming_status',
	profitability   : 'profitability',
	betsAmountTo    : 'bets_amount_to',
	maxStakeFrom    : 'max_stake_from',
	minStakeFrom    : 'min_stake_from',
	betsCountFrom   : 'bets_count_from',
	birthDateFrom   : 'birth_date_from',
	documentNumber  : 'document_number',
	registrationIP  : 'registration_ip',
	betsAmountFrom  : 'bets_amount_from',
	lastBetDateTo   : 'last_bet_date_to',
	depositsRangeTo : 'deposits_range_to',
	validationStatus: 'validation_status',
	genderID        : 'sex',

	depositsAmountTo       : 'deposits_amount_to',
	excludeMassBonus       : 'exclude_mass_bonus',
	lastBetAmountTo        : 'last_bet_amount_to',
	lastBetDateFrom        : 'last_bet_date_from',
	lastLoginDateTo        : 'last_login_date_to',
	depositsRangeFrom      : 'deposits_range_from',
	verificationStatus     : 'verification_status',
	withdrawalRangeTo      : 'withdrawal_range_to',
	depositsAmountFrom     : 'deposits_amount_from',
	lastBetAmountFrom      : 'last_bet_amount_from',
	lastLoginDateFrom      : 'last_login_date_from',
	withdrawalAmountTo     : 'withdrawal_amount_to',
	firstDepositDateTo     : 'first_deposit_date_to',
	withdrawalRangeFrom    : 'withdrawal_range_from',
	withdrawalAmountFrom   : 'withdrawal_amount_from',
	countryOfRegistration  : 'country_of_registration',
	firstDepositDateFrom   : 'first_deposit_date_from',
	firstWithdrawalDateTo  : 'first_withdrawal_date_to',
	firstWithdrawalDateFrom: 'first_withdrawal_date_from',
};

const { isValidID, isValidString, isValidArray, isValidDateRange } = queryToFilterValidaion;
export const initFilterTypes = {
	id          : isValidID,
	createdBy   : isValidID,
	name        : isValidString,
	forced      : isValidString,
	freeSpinType: isValidID,
	currency    : isValidArray,
	createDate  : isValidDateRange,
};

const listAdapter = createListAdapter();

// Params -----------------------------------------------------------------------------------------

export function getListParams(filter, sorting = null, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
	}


	builder.addRangeField(rules.isDateTimeRange, filter.createDate, [fields.createdDateFrom, fields.createdDateTo]);

	builder.addField(rules.isID,		'id', 'id');
	builder.addField(rules.isArrayID,	'currency', fields.currencyIDs);
	builder.addField(rules.isString,	'name', fields.name);
	Number(filter.forced && filter.forced >= 0) && builder.addField(rules.bool, 'forced', fields.forced);
	builder.addField(rules.isID,		'freeSpinType', fields.freeSpinType);
	builder.addField(rules.isID,		'createdBy', fields.createdBy);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapting ---------------------------------------------------------------------------------------

export function adaptList(rawUsersList = [], currencies = []) {

	listAdapter.clearExcludes();
	const currencyEntities = createEntities(currencies);
	const adaptedList	= listAdapter.adaptList(rawUsersList).map(bonus => {

		const titlesAdapter		= createTitleAdapter(bonus);
		const userFilterAdapter = createUserFilterAdapter(bonus);

		titlesAdapter.clearExcludes();
		userFilterAdapter.clearExcludes();
		bonus.userFilter = userFilterAdapter.adapt(bonus.userFilter);
		bonus.userFilter = {
			...bonus.userFilter,
			regDateRange     : [bonus.userFilter.dateFrom, bonus.userFilter.dateTo],
			age              : [bonus.userFilter.ageFrom, bonus.userFilter.ageTo],
			birthday         : [bonus.userFilter.birthDateFrom, bonus.userFilter.birthDateTo],
			firstDeposit     : [bonus.userFilter.firstDepositDateFrom, bonus.userFilter.firstDepositDateTo],
			firstWithdrawal  : [bonus.userFilter.firstWithdrawalDateFrom, bonus.userFilter.firstWithdrawalDateTo],
			depositsRange    : [bonus.userFilter.depositsRangeFrom, bonus.userFilter.depositsRangeTo],
			withdrawalsRange : [bonus.userFilter.withdrawalRangeFrom, bonus.userFilter.withdrawalRangeTo],
			withdrawalsAmount: [bonus.userFilter.withdrawalAmountFrom, bonus.userFilter.withdrawalAmountTo],
			depositsAmount   : [bonus.userFilter.depositsAmountFrom, bonus.userFilter.depositsAmountTo],
			betsCount        : [bonus.userFilter.betsCountFrom, bonus.userFilter.betsCountTo],
			lastBetAmount    : [bonus.userFilter.lastBetAmountFrom, bonus.userFilter.lastBetAmountTo],
			betsAmount       : [bonus.userFilter.betsAmountFrom, bonus.userFilter.betsAmountTo],
			minStake         : [bonus.userFilter.minStakeFrom, bonus.userFilter.minStakeTo],
			maxStake         : [bonus.userFilter.maxStakeFrom, bonus.userFilter.maxStakeTo],
			lastBetDate      : [bonus.userFilter.lastBetDateFrom, bonus.userFilter.lastBetDateTo],
			lastLoginDate    : [bonus.userFilter.lastLoginDateFrom, bonus.userFilter.lastLoginDateTo],
		};
		bonus.titles			= titlesAdapter.adaptList(bonus.titles);
		bonus.currencyCodes = bonus.currency.map(cur => currencyEntities[cur] ? currencyEntities[cur].code : cur.toString());

		return bonus;
	});
	return adaptedList;
}

// Adapters ---------------------------------------------------------------------------------------
export function createTitleAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.id,     'langID', fields.langID);
	adapter.addField(rules.id,     'id', fields.id);
	adapter.addField(rules.id,     'bonusID', fields.bonusID);
	adapter.addField(rules.id,     'bonusType', fields.bonusType);

	return adapter;
}
export function createUserFilterAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'gamingStatusID', userFilterFields.gamingStatus);
	adapter.addField(rules.string, 'userID', userFilterFields.id);
	adapter.addField(rules.id, 'statusID', userFilterFields.statusID);

	adapter.addField(rules.number, 'ageFrom', userFilterFields.ageFrom);
	adapter.addField(rules.number, 'ageTo', userFilterFields.ageTo);
	adapter.addField(rules.number, 'betsAmountFrom', userFilterFields.betsAmountFrom);
	adapter.addField(rules.number, 'betsAmountTo', userFilterFields.betsAmountTo);
	adapter.addField(rules.number, 'betsCountFrom', userFilterFields.betsCountFrom);
	adapter.addField(rules.number, 'betsCountTo', userFilterFields.betsCountTo);

	adapter.addField(rules.number, 'depositsAmountFrom', userFilterFields.depositsAmountFrom);
	adapter.addField(rules.number, 'depositsAmountTo', userFilterFields.depositsAmountTo);
	adapter.addField(rules.number, 'depositsRangeFrom', userFilterFields.depositsRangeFrom);
	adapter.addField(rules.number, 'depositsRangeTo', userFilterFields.depositsRangeTo);
	adapter.addField(rules.number, 'lastBetAmountFrom', userFilterFields.lastBetAmountFrom);
	adapter.addField(rules.number, 'lastBetAmountTo', userFilterFields.lastBetAmountTo);
	adapter.addField(rules.number, 'maxStakeFrom', userFilterFields.maxStakeFrom);
	adapter.addField(rules.number, 'maxStakeTo', userFilterFields.maxStakeTo);
	adapter.addField(rules.number, 'minStakeFrom', userFilterFields.minStakeFrom);
	adapter.addField(rules.number, 'minStakeTo', userFilterFields.minStakeTo);
	adapter.addField(rules.number, 'profitabilityGradeID', userFilterFields.profitability);
	adapter.addField(rules.number, 'sex', userFilterFields.sex);
	adapter.addField(rules.number, 'genderID', userFilterFields.sex);
	adapter.addField(rules.number, 'verificationStatusID', userFilterFields.verificationStatus);
	adapter.addField(rules.number, 'withdrawalAmountFrom', userFilterFields.withdrawalAmountFrom);
	adapter.addField(rules.number, 'withdrawalAmountTo', userFilterFields.withdrawalAmountTo);
	adapter.addField(rules.number, 'withdrawalRangeFrom', userFilterFields.withdrawalRangeFrom);
	adapter.addField(rules.number, 'withdrawalRangeTo', userFilterFields.withdrawalRangeTo);

	adapter.addField(rules.arrayString, 'country', userFilterFields.country);
	adapter.addField(rules.arrayString, 'registrationCountry', userFilterFields.countryOfRegistration);
	adapter.addField(rules.arrayString, 'currency', userFilterFields.currencyIDs);
	adapter.addField(rules.arrayString, 'riskGroup', userFilterFields.riskGroup);
	adapter.addField(rules.arrayString, 'validationStatus', userFilterFields.validationStatus);
	
	adapter.addField(rules.string, 'customerIP', userFilterFields.customerIP);
	adapter.addField(rules.string, 'registrationIP', userFilterFields.registrationIP);
	adapter.addField(rules.string, 'dateFrom', userFilterFields.dateFrom);
	adapter.addField(rules.string, 'dateTo', userFilterFields.dateTo);
	adapter.addField(rules.string, 'documentNumber', userFilterFields.documentNumber);
	adapter.addField(rules.string, 'domain', userFilterFields.domain);
	adapter.addField(rules.string, 'userEmail', userFilterFields.email);
	adapter.addField(rules.string, 'firstDepositDateFrom', userFilterFields.firstDepositDateFrom);
	adapter.addField(rules.string, 'firstDepositDateTo', userFilterFields.firstDepositDateTo);
	adapter.addField(rules.string, 'userFirstName', userFilterFields.firstName);
	adapter.addField(rules.string, 'firstWithdrawalDateFrom', userFilterFields.firstWithdrawalDateFrom);
	adapter.addField(rules.string, 'firstWithdrawalDateTo', userFilterFields.firstWithdrawalDateTo);
	adapter.addField(rules.string, 'lastBetAmountFrom', userFilterFields.lastBetAmountFrom);
	adapter.addField(rules.string, 'lastBetAmountTo', userFilterFields.lastBetAmountTo);
	adapter.addField(rules.string, 'lastLoginDateFrom', userFilterFields.lastLoginDateFrom);
	adapter.addField(rules.string, 'lastBetDateFrom', userFilterFields.lastBetDateFrom);
	adapter.addField(rules.string, 'lastBetDateTo', userFilterFields.lastBetDateTo);
	adapter.addField(rules.string, 'birthDateFrom', userFilterFields.birthDateFrom);
	adapter.addField(rules.string, 'birthDateTo', userFilterFields.birthDateTo);
	
	adapter.addField(rules.string, 'lastLoginDateTo', userFilterFields.lastLoginDateTo);
	adapter.addField(rules.string, 'userLastName', userFilterFields.lastName);
	adapter.addField(rules.string, 'userName', userFilterFields.username);
	adapter.addField(rules.string, 'websiteID', userFilterFields.websiteID);
	
	adapter.addField(rules.string, 'description', userFilterFields.description);
	
	adapter.addField(rules.id,     'langID', userFilterFields.langID);
	adapter.addField(rules.id,     'id', userFilterFields.id);
	adapter.addField(rules.id,     'bonusID', userFilterFields.bonusID);
	adapter.addField(rules.id,     'bonusType', userFilterFields.bonusType);
	
	adapter.addField(rules.bool, 'excludeMassBonus', userFilterFields.excludeMassBonus);

	return adapter;
}

function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id,              'id', fields.id);
	adapter.addField(rules.id,				'bonusType', fields.bonusType);
	adapter.addField(rules.id,				'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.id,				'websiteID', fields.websiteID);
	adapter.addField(rules.fullDate,        'createDate', fields.createDate);
	adapter.addField(rules.arrayID,			'currency', fields.currencyIDs);
	adapter.addField(rules.string,          'name', fields.name);
	adapter.addField(rules.bool,            'forced', fields.forced);
	adapter.addField(rules.id,				'freeSpinType', fields.freeSpinType);
	adapter.addField(rules.id,				'typeID', fields.freeSpinType);
	adapter.addField(rules.id,				'createdBy', fields.createdBy);
	
	adapter.addField(rules.string,			'casinoGameName', fields.casinoGameName);
	adapter.addField(rules.string,			'logoUrl', fields.logoUrl);
	adapter.addField(rules.bool,			'autoSendLog', fields.autoSendLog);
	adapter.addField(rules.arrayObject,     'titles', fields.titles);
	adapter.addField(rules.arrayString,		'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.number, 			'betPerLine', fields.betPerLine);
	adapter.addField(rules.number, 			'daysToExpireBonus', fields.daysToExpireBonus);
	adapter.addField(rules.number, 			'daysToExpireWallet', fields.daysToExpireWallet);
	adapter.addField(rules.number, 			'denomination', fields.denomination);
	adapter.addField(rules.number, 			'denominationValue', fields.denominationValue);
	adapter.addField(rules.number, 			'lines', fields.lines);
	adapter.addField(rules.number, 			'spinsTotal', fields.spinsTotal);
	adapter.addField(rules.number, 			'wageringTurnover', fields.wageringTurnover);
	
	adapter.addField(rules.number, 			'betAmount', fields.betAmount);
	adapter.addField(rules.number, 			'leftSpins', fields.leftSpins);
	
	adapter.addField(rules.fullDate, 		'claimDate', fields.claimDate);

	adapter.addField(rules.arrayNumber, 'gameIDs', fields.gameIDs);
	adapter.addField(rules.arrayNumber, 'categoryIDs', fields.categoryIDs);
	adapter.addField(rules.arrayNumber, 'tagIDs', fields.tagIDs);
	adapter.addField(rules.arrayID, 'integratorIDs', fields.integratorIDs);
	adapter.addField(rules.arrayID, 'providerIDs', fields.providerIDs);

	adapter.addField(rules.object, 			'userFilter', fields.userFilter);
	adapter.addField(rules.object, 			'count', fields.count);
	

	return adapter;
}
